<template>
	<div>
		<template v-if="status == 0">
			<div v-if="smsnum == 1 || smsnum == 2" style="font-size: 18px; color: #1989fa; margin: 40px 0">
				{{ business_name }}
			</div>
			<OneSms v-if="smsnum == 1" :linkId="linkId"></OneSms>
			<TwoSms v-if="smsnum == 2" :linkId="linkId"></TwoSms>

			<div v-if="smsnum == 1 || smsnum == 2" class="content-title">充值说明:</div>
			<div v-if="smsnum == 1 || smsnum == 2" class="content">
				{{ info.desc }}
			</div>
		</template>

		<Direct v-else :info="info"></Direct>
	</div>
</template>

<script setup>
import TwoSms from './TowSms.vue';
import OneSms from './OneSms.vue';
import Direct from './Direct.vue';
import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
const { proxy } = getCurrentInstance();

let business_name = ref('');

let smsnum = ref(1);
let status = ref(0);
let info = ref({});
let linkId = '';

linkId = getSearchParams().linkId;

function getSearchParams() {
	const params = new URLSearchParams(window.location.search);
	let searchParams = {};
	for (const [key, value] of params.entries()) {
		searchParams[key] = value;
	}
	return searchParams;
}

function getData() {
	proxy.$axios.post('/order/queryOrderInfo', { linkId }).then((res) => {
		business_name.value = res.data.name;
		smsnum.value = res.data.smsnum;
		status.value = res.data.status;
		info.value = res.data;
		if (res.data.status == 3) {
			setTimeout(() => {
				getData();
			}, 5000);
		}
	});
}
getData();
</script>
<style lang="scss" scoped>
.policy {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 16px;
	span {
		color: rgb(25, 137, 250);
	}
}
.suffix {
	display: flex;
	align-items: center;
}
.suffix--line {
	width: 1px;
	height: 24px;
	background-color: #f6f6f6;
	margin-right: 16px;
}

.image {
	width: 72px;
	height: 36px;
	display: block;
	margin-top: -6px;
	margin-bottom: -6px;
}

.verify {
	color: #07c160;
	font-size: 16px;
}

.box {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.upload {
	--td-upload-grid-columns: 3;
}
.content {
	text-align: left;
	font-size: 14px;
}
.content-title {
	font-size: 16px;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 10px;

}
.textarea {
	height: 100px;
	width: 100%;
}
.button-group {
	margin-top: 20px;

	.t-button {
		background-color: #07c160 !important;
		border-color: #07c160;
		width: 100%;
	}
	.t-button::after {
		border-color: #07c160;
	}
}
</style>
