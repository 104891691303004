import axios from 'axios';

import { Message } from 'tdesign-mobile-vue';
let baseURL = 'https://link.aeb.cc';
const service = axios.create({
	baseURL,
	timeout: 60000,
	headers: { 'Content-Type': 'application/json;charset=utf-8' },
});

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

// 响应拦截器
service.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		return Promise.reject(error.message);
	}
);
const post = function (url, param = {}, config) {
	return new Promise((resolve, reject) => {
		service
			.post(url, param, config)
			.then((resp) => {
				resolve(resp.data);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

const get = function (url, param = {}, config) {
	console.log(param);
	return new Promise((resolve, reject) => {
		service
			.get(url, {
				params: param,
				...config,
			})
			.then((resp) => {
				resolve(resp.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const del = function (url, param = {}, config) {
	return new Promise((resolve, reject) => {
		service['delete'](url, {
			data: param,
			...config,
		})
			.then((resp) => {
				resolve(resp.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

const put = function (url, param = {}, config) {
	return new Promise((resolve, reject) => {
		service
			.put(url, param, config)
			.then((resp) => {
				resolve(resp.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
const patch = function (url, param = {}, config) {
	return new Promise((resolve, reject) => {
		service
			.patch(url, param, config)
			.then((resp) => {
				resolve(resp.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export default { post, get, del, put, patch };
