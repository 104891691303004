<template>
	<div>
		<t-form
			ref="form"
			:data="formData"
			:rules="rules"
			reset-type="initial"
			show-error-message
			label-align="left"
			@submit="onSubmit"
			scroll-to-first-error="auto">
			<t-form-item label="手机号" name="phone">
				<t-input v-model="formData.phone" borderless :clearable="false" placeholder="请输入手机号"> </t-input>
			</t-form-item>
			<t-form-item label="验证码" name="smscode">
				<t-input v-model="formData.smscode" borderless :clearable="false" placeholder="请输入验证码">
					<template #suffixIcon>
						<div @click="sendsms" v-if="time == 0" class="verify" aria-role="button">{{ smsText }}</div>
						<div v-else class="verify" style="color: rgba(0, 0, 0, 0.5)" aria-role="button">{{ smsText }}</div>
					</template>
				</t-input>
			</t-form-item>

			<div class="button-group">
				<t-button theme="primary" type="submit" size="large">提交验证码</t-button>
			</div>
		</t-form>

		<t-overlay :visible="visible">
			<t-loading :text="loadingText" theme="spinner" size="40px" fullscreen />
		</t-overlay>
	</div>
</template>

<script setup>
import { ref, getCurrentInstance, reactive, onMounted, defineProps } from 'vue';
const { proxy } = getCurrentInstance();
import { Toast, Message } from 'tdesign-mobile-vue';

let smsText = ref('发送验证码');
let loadingText = ref('发送中...');
let time = ref(0);

let visible = ref(false);
let props = defineProps({
	linkId: {
		type: String,
		default: '',
	},
});
let linkId = props.linkId;

const formData = reactive({
	phone: '',
	smscode: '',
});
const form = ref(null);

const rules = {
	phone: [{ validator: (val) => val.length === 11, message: '手机号输入错误' }],
	smscode: [{ validator: (val) => val.length > 1, message: '请输入验证码' }],
};

function sendsms() {
	if (formData.phone.length != 11) {
		Message.error({
			offset: [10, 16],
			icon: true,
			zIndex: 20000,
			content: '手机号错误',
		});
		return;
	}
	loadingText.value = '发送中...';
	visible.value = true;

	proxy.$axios
		.post('/link/sendSms', { linkId, smsSeq: '1', phone: formData.phone })
		.then((resp) => {
			console.log(resp);
			visible.value = false;

			if (resp.status === 200) {
				let timer = null;
				time.value = 59;
				timer = setInterval(() => {
					time.value--;
					smsText.value = `${time.value}秒后重新发送`;
					if (time.value == 0) {
						clearInterval(timer);
						smsText.value = `发送验证码`;
					}
				}, 1000);

				Toast({
					theme: 'success',
					direction: 'column',
					message: resp.msg,
				});
			} else if (resp.status === 201) {
				window.location.reload();
			} else {
				Toast({
					message: resp.msg,
				});
			}
		})
		.catch((e) => {
			Toast({
				message: '系统错误，请联系管理员',
			});

			visible.value = false;
		});
}

const onSubmit = (e) => {
	if (e.validateResult) {
		loadingText.value = '提交中...';
		visible.value = true;
		proxy.$axios
			.post('/link/submit', { linkId, smsSeq: 1, phone: formData.phone, smscode: formData.smscode })
			.then((resp) => {
				console.log(`🚀 ~ .then ~ resp:`, resp);
				visible.value = false;
				if (resp.status == 200) {
					window.location.reload();
				} else if (resp.status === 201) {
					window.location.reload();
				} else {
					Toast({
						message: resp.msg,
					});
				}
			});
	}
};

onMounted(() => {
	form.value.setValidateMessage(rules);
});
</script>
<style lang="scss" scoped>
.policy {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 16px;
	span {
		color: rgb(25, 137, 250);
	}
}
.suffix {
	display: flex;
	align-items: center;
}
.suffix--line {
	width: 1px;
	height: 24px;
	background-color: #f6f6f6;
	margin-right: 16px;
}

.image {
	width: 72px;
	height: 36px;
	display: block;
	margin-top: -6px;
	margin-bottom: -6px;
}

.verify {
	color: #07c160;
	font-size: 16px;
}

.box {
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.upload {
	--td-upload-grid-columns: 3;
}
.textarea {
	height: 100px;
	width: 100%;
}
.button-group {
	margin-top: 20px;

	.t-button {
		background-color: #07c160 !important;
		border-color: #07c160;
		width: 100%;
	}
	.t-button::after {
		border-color: #07c160;
	}
}
</style>
