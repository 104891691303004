<template>
	<div class="Direct">
		<template v-if="info.status == 1">
			<img width="100" height="100" :src="require('../assets/img/no.png')" alt="" srcset="" />
			<div class="title">充值失败</div>
			<div class="subtitle">{{ info.note }}</div>
		</template>
		<template v-if="info.status == 2">
			<img width="100" height="100" :src="require('../assets/img/ok.png')" alt="" srcset="" />
			<div class="title">充值成功</div>
			<div class="subtitle">具体到账时间以短信通知为准!</div>
		</template>
		<template v-if="info.status == 3">
			<img width="100" height="100" :src="require('../assets/img/wait.png')" alt="" srcset="" />
			<div class="title">充值中</div>
			<div class="subtitle">请耐心等待...</div>
		</template>
		<div style="width: 100%">
			<t-cell-group bordered>
				<t-cell title="充值号码" :note="info.phone" />
				<t-cell title="充值额度" :note="info.name" />
				<t-cell title="充值时间" :note="parseTime(info.update_time)" />
				<t-cell title="充值反馈">
					<span v-if="info.status == 1" style="color: #ff5722"> 请联系客服退款 </span>
					<span v-if="info.status == 2" style="color: #2680FF"> 1-10分钟内到账 </span>
					<span v-if="info.status == 3" style="color: #F19A2A"> 请等待1-5分钟 </span>
				</t-cell>
			</t-cell-group>
		</div>

	
	</div>
</template>

<script setup>
import { defineProps } from 'vue';
defineProps({
	info: {
		type: Object,
	},
});

function parseTime(date, format = 'yyyy-MM-dd hh:mm:ss') {
	if (!date) {
		return '';
	}
	let curDate = new Date(date);
	const o = {
		'M+': curDate.getMonth() + 1, // 月份
		'd+': curDate.getDate(), // 日
		'h+': curDate.getHours() >= 10 ? curDate.getHours() : '0' + curDate.getHours(), // 小时
		'm+': curDate.getMinutes() >= 10 ? curDate.getMinutes() : '0' + curDate.getMinutes(), // 分
		's+': curDate.getSeconds() >= 10 ? curDate.getSeconds() : '0' + curDate.getSeconds(), // 秒
		'q+': Math.floor((curDate.getMonth() + 3) / 3), // 季度
		S: curDate.getMilliseconds(), // 毫秒
	};
	if (/(y+)/.test(format)) {
		format = format.replace(RegExp.$1, (curDate.getFullYear() + '').substr(4 - RegExp.$1.length));
	}
	for (let k in o) {
		if (new RegExp('(' + k + ')').test(format)) {
			format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
		}
	}
	return format;
}
</script>
<style lang="scss" scoped>
.Direct {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
	.title {
		color: #222;
		font-size: 22px;
		margin-bottom: 10px;
	}
	.subtitle {
		text-align: center;
		color: #777778;
		font-size: 14px;
		margin-bottom: 30px;
	}
	.item {
		height: 35px;
		margin-top: 5px;
		background: #fbf4e4;
		border-radius: 5px;
		display: flex;
		padding: 15px;
		width: 80vw;
		justify-content: space-between;
		align-items: center;
		.item-btn {
			border-radius: 100px;
			border: none;
			color: #fff;
			padding: 3px 15px;
			font-size: 14px;
		}
		.item-text {
			font-size: 16px;
		}
	}
}
</style>
